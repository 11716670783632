import React from "react";

export default function LockIcon(props: any) {
  return (
    <svg width={18} height={24} {...props}>
      <g fill="#005AEE">
        <path d="M7.818 16.608l-.315 2.837A.501.501 0 0 0 8 20h2a.5.5 0 0 0 .497-.555l-.315-2.837c.512-.372.818-.961.818-1.608 0-1.103-.897-2-2-2s-2 .897-2 2c0 .647.306 1.236.818 1.608zM9 14c.551 0 1 .449 1 1a.992.992 0 0 1-.568.896.5.5 0 0 0-.28.506L9.442 19h-.883l.289-2.598a.5.5 0 0 0-.28-.506A.992.992 0 0 1 8 15c0-.551.449-1 1-1z" />
        <path d="M17.5 9H16V7c0-3.86-3.14-7-7-7S2 3.14 2 7v2H.5a.5.5 0 0 0-.5.5V22c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V9.5a.5.5 0 0 0-.5-.5zM3 7c0-3.309 2.691-6 6-6s6 2.691 6 6v2h-1V7c0-2.757-2.243-5-5-5S4 4.243 4 7v2H3V7zm10 0v2H5V7c0-2.206 1.794-4 4-4s4 1.794 4 4zm4 15c0 .551-.449 1-1 1H2c-.551 0-1-.449-1-1V10h16v12z" />
      </g>
    </svg>
  );
}
