import React from "react";
import * as palette from "@govlaunch/palette";
import { ITag } from "~/types/types";

interface IProjectTags {
  tags: Pick<ITag, "_id" | "name">[];
}

const TagsPreview: React.FunctionComponent<IProjectTags> = ({ tags }) => {
  if (tags.length === 0) {
    return null;
  }

  if (tags.length > 1) {
    return (
      <div
        css={{
          display: "flex",
        }}
      >
        <span
          css={{
            padding: "5px 10px",
            backgroundColor: "rgba(219, 236, 247, 0.5)",
            borderTopLeftRadius: 12.5,
            borderBottomLeftRadius: 12.5,
            fontSize: 12,
            color: palette.text,
            display: "inline-flex",
          }}
        >
          {tags[0].name}
        </span>

        <span
          css={{
            lineHeight: "12px",
            padding: "5px 10px",
            backgroundColor: "#48A0DA",
            borderTopRightRadius: 12.5,
            borderBottomRightRadius: 12.5,
            fontSize: 10,
            display: "inline-flex",
            color: palette.white,
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          +{tags.length - 1}
        </span>
      </div>
    );
  }

  return (
    <span
      css={{
        padding: "5px 10px",
        backgroundColor: "rgba(219, 236, 247, 0.5)",
        borderRadius: 12.5,
        fontSize: 12,
        color: palette.text,
        display: "inline-flex",
      }}
    >
      {tags[0].name}
    </span>
  );
};

export default TagsPreview;
