import * as palette from "@govlaunch/palette";
import React, { ReactElement } from "react";

export default function StoryListItemDividers(props: any): ReactElement {
  return (
    <div
      {...props}
      css={{
        "& > :not(:first-of-type)": {
          borderTop: `solid 1px ${palette.lightestGray}`,
        },
      }}
    />
  );
}
