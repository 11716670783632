import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import React from "react";
import {
  IToggleBookmarkMutation,
  IToggleBookmarkMutationVariables,
} from "~/lib/mutations/__generated__/ToggleBookmarkMutation.generated";

type ITargetType = "PRODUCT" | "STORY" | "PROJECT";

interface IToggleBookmarkProps {
  targetId: string;
  targetType: ITargetType;
  viewerDidBookmark: boolean;
  children: (toggleBookmark: () => void) => any;
}

export default function ToggleBookmark({ targetId, targetType, viewerDidBookmark, children }: IToggleBookmarkProps) {
  return (
    <Mutation<IToggleBookmarkMutation, IToggleBookmarkMutationVariables>
      mutation={gql`
        mutation ToggleBookmark($targetId: ObjectId!, $targetType: BookmarkType!) {
          toggleBookmark(targetId: $targetId, targetType: $targetType) {
            __typename
            ... on Product {
              _id
              viewerDidBookmark
            }
            ... on Story {
              _id
              viewerDidBookmark
            }
            ... on Project {
              _id
              viewerDidBookmark
            }
          }
        }
      `}
      variables={{
        targetId,
        targetType: targetType as any,
      }}
    >
      {(toggleBookmark) => {
        return children(() => {
          toggleBookmark({
            optimisticResponse: {
              __typename: "Mutation",
              toggleBookmark: {
                __typename: getTypenameByTargetType(targetType) as any,
                _id: targetId,
                viewerDidBookmark: !viewerDidBookmark,
              },
            },
          });
        });
      }}
    </Mutation>
  );
}

function getTypenameByTargetType(targetType: ITargetType): string {
  if (targetType === "STORY") {
    return "Story";
  } else if (targetType === "PRODUCT") {
    return "PRODUCT";
  } else {
    return "PROJECT";
  }
}
