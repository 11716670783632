import React, { Fragment } from "react";
import * as palette from "@govlaunch/palette";
import Dotdotdot from "~/components/text/Dotdotdot";
import moment from "moment";
import { Toggle } from "react-powerplug";
import Popover from "@govlaunch/popover";
import { IFlatResource } from "~/types/types";
import useShowMoreCounter from "~/lib/hooks/useShowMoreCounter";
import useIsMobile from "~/lib/hooks/useIsMobile";
import { Anchor } from "@govlaunch/web";
import { FormattedMessage } from "react-intl";

interface IShowMore {
  onClick: () => any;
}

interface IResourcesProps {
  resources: IFlatResource[];
  titleColor?: string;
  dataIntercomTarget?: string | null;
}

interface IIconProps {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  onClick?: (e?: any) => any;
}

const Resources: React.FunctionComponent<IResourcesProps> = ({
  resources,
  titleColor = palette.sealBlue,
  dataIntercomTarget,
}) => {
  const { offset, hasMore, showMore } = useShowMoreCounter(resources.length, 4);
  const paginated = resources ? resources.slice(0, offset) : [];
  const isMobile = useIsMobile();

  if (!Array.isArray(resources) || resources.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        backgroundColor: palette.white,
        borderRadius: 5,
        border: `solid 1px ${palette.lightestGray}`,
      }}
      data-intercom-target={dataIntercomTarget}
    >
      <div
        css={{
          padding: 15,
          textTransform: "uppercase",
          fontSize: 12,
          color: titleColor,
          letterSpacing: 0.5,
          fontWeight: 500,
        }}
      >
        <FormattedMessage defaultMessage="Resources" id="c/KktL" />
      </div>

      <div
        css={{
          borderTop: `solid 1px ${palette.lightestGray}`,
          "> div:not(:first-of-type)": {
            borderTop: `solid 1px ${palette.lightestGray}`,
          },
        }}
      >
        {paginated.map((resource, index) => {
          const isRecentlyAdded = isFresh(resource, resources);

          return (
            // eslint-disable-next-line
            <Fragment key={index}>
              {resource.__typename === "DownloadResource" && (
                <Resource>
                  <div
                    css={{
                      width: 24,
                      height: 24,
                      minWidth: 24,
                      minHeight: 24,
                    }}
                  >
                    <DocumentIcon
                      width={24}
                      height={24}
                      style={{
                        minWidth: 24,
                        minHeight: 24,
                      }}
                    />
                  </div>

                  <div
                    css={{
                      marginLeft: 10,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Anchor
                      css={{
                        fontSize: 14,
                        color: palette.darkGray,
                        textDecoration: "none",
                        fontWeight: 500,
                        lineHeight: 1.2,
                        "&:hover": {
                          color: palette.darkGray,
                        },
                      }}
                      href={resource.url as string}
                      download={resource.filename}
                      external="govlaunch"
                    >
                      <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                    </Anchor>

                    {isRecentlyAdded && (
                      <span
                        css={{
                          fontSize: 10,
                          marginTop: 2.5,
                          color: palette.darkSkyLightBlue,
                        }}
                      >
                        <FormattedMessage defaultMessage="🔥 Recently added" id="wPk7g2" />
                      </span>
                    )}
                  </div>
                </Resource>
              )}

              {resource.__typename === "LinkResource" && (
                <Resource>
                  <LinkIcon
                    width={24}
                    height={24}
                    style={{
                      minWidth: 24,
                      minHeight: 24,
                    }}
                  />

                  <div
                    css={{
                      marginLeft: 10,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Anchor
                      css={{
                        fontSize: 14,
                        color: palette.darkGray,
                        fontWeight: 500,
                        textDecoration: "none",
                        lineHeight: 1.2,
                        "&:hover": {
                          color: palette.darkGray,
                        },
                      }}
                      href={resource.url as string}
                      external="outside-govlaunch"
                    >
                      <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                    </Anchor>

                    {isRecentlyAdded && (
                      <span
                        css={{
                          fontSize: 10,
                          marginTop: 2.5,
                          color: palette.darkSkyLightBlue,
                        }}
                      >
                        <FormattedMessage defaultMessage="🔥 Recently added" id="wPk7g2" />
                      </span>
                    )}
                  </div>
                </Resource>
              )}

              {resource.__typename === "TextResource" && (
                <Toggle>
                  {({ on, toggle }) => (
                    <Popover
                      placement="right"
                      isOpen={on && !isMobile}
                      onClickOutside={toggle}
                      boxStyle={{
                        boxShadow: "0 5px 30px 0 rgba(34, 35, 40, 0.2)",
                      }}
                      render={() => (
                        <div
                          css={{
                            padding: 15,
                            maxWidth: 320,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {resource.body}
                        </div>
                      )}
                    >
                      {({ innerRef }: { innerRef: React.RefObject<HTMLSpanElement> }) => (
                        <Resource onClick={toggle}>
                          <FontIcon
                            width={24}
                            height={24}
                            style={{
                              minWidth: 24,
                              minHeight: 24,
                            }}
                          />

                          <div
                            css={{
                              marginLeft: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              ref={innerRef}
                              css={{
                                fontSize: 14,
                                color: palette.darkGray,
                                lineHeight: 1.2,
                                fontWeight: 500,
                              }}
                            >
                              <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                            </span>

                            {isRecentlyAdded && (
                              <span
                                css={{
                                  fontSize: 10,
                                  marginTop: 2.5,
                                  color: palette.darkSkyLightBlue,
                                }}
                              >
                                <FormattedMessage defaultMessage="🔥 Recently added" id="wPk7g2" />
                              </span>
                            )}

                            {isMobile && on && (
                              <span
                                css={{
                                  fontSize: 13,
                                  color: palette.mediumGray,
                                  marginTop: 5,
                                }}
                              >
                                {resource.body}
                              </span>
                            )}
                          </div>
                        </Resource>
                      )}
                    </Popover>
                  )}
                </Toggle>
              )}
            </Fragment>
          );
        })}
      </div>

      {hasMore && <ShowMore onClick={showMore} />}
    </div>
  );
};

function isFresh(resource: IFlatResource, resources: IFlatResource[]) {
  const weekStart = moment().startOf("week");
  const isWithinTheWeek = moment(resource.addedAt) >= weekStart;

  if (isWithinTheWeek) {
    const daysAgo = moment(resource.addedAt).subtract(4, "days");

    return resources.slice().some(maybe => {
      return moment(maybe.addedAt) <= daysAgo;
    });
  }

  return false;
}

const ShowMore: React.FunctionComponent<IShowMore> = props => (
  <div
    role="button"
    aria-label="Show more"
    css={{
      padding: 15,
      textTransform: "uppercase",
      color: palette.sealBlue,
      fontSize: 12,
      textAlign: "center",
      cursor: "pointer",
      borderTop: `solid 1px ${palette.lightestGray}`,
      "&:hover": {
        color: palette.darkGray,
      },
    }}
    {...props}
  >
    <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
  </div>
);

const DocumentIcon: React.FunctionComponent<IIconProps> = props => (
  <svg viewBox="0 0 16 22" width={16} height={22} {...props}>
    <g fill="#28D094" fillRule="nonzero">
      <path d="M12.643 10H3.357C3.143 10 3 9.8 3 9.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5zM12.643 7H8.357C8.143 7 8 6.8 8 6.5s.143-.5.357-.5h4.286c.214 0 .357.2.357.5s-.143.5-.357.5z" />
      <path d="M15.636 22H.364C.145 22 0 21.853 0 21.633v-15.4c0-.073.073-.22.073-.293L5.89.073c.073 0 .218-.073.29-.073h9.455c.219 0 .364.147.364.367v21.266c0 .22-.145.367-.364.367zM.727 21.267h14.546V.733H6.327L.727 6.38v14.887z" />
      <path d="M6.611 7H.39C.156 7 0 6.844 0 6.611s.156-.389.389-.389h5.833V.39c0-.233.156-.389.39-.389C6.843 0 7 .156 7 .389V6.61C7 6.844 6.844 7 6.611 7zM12.643 13H3.357C3.143 13 3 12.8 3 12.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5zM12.643 16H3.357C3.143 16 3 15.8 3 15.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5zM12.643 19H3.357C3.143 19 3 18.8 3 18.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5z" />
    </g>
  </svg>
);

const FontIcon: React.FunctionComponent<IIconProps> = props => (
  <svg viewBox="0 0 340.111 340.111" width="1em" height="1em" {...props}>
    <path
      fill="#28D094"
      d="M340.111 76.316V65.175h-47.905v11.141h18.382v187.478h-18.382v11.141h47.905v-11.141h-18.382V76.316z"
    />
    <g fill="#28D094">
      <path d="M2.067 229.59l56.068-126.615c3.909-8.731 11.03-14.018 20.684-14.018h2.068c9.648 0 16.544 5.286 20.449 14.018l56.07 126.615c1.149 2.528 1.84 4.825 1.84 7.124 0 9.421-7.354 17.004-16.776 17.004-8.272 0-13.788-4.825-17.004-12.18l-10.799-25.275H43.891l-11.26 26.426c-2.988 6.893-8.961 11.029-16.315 11.029C7.121 253.718 0 246.365 0 237.173c0-2.528.918-5.055 2.067-7.583zm99.501-44.579l-22.291-53.082-22.289 53.082h44.58zM176.011 216.951v-.46c0-26.885 20.452-39.294 49.635-39.294 12.41 0 21.373 2.068 30.105 5.056v-2.068c0-14.478-8.963-22.519-26.427-22.519-9.651 0-17.464 1.378-24.128 3.447-2.067.689-3.447.918-5.058.918-8.04 0-14.474-6.204-14.474-14.246 0-6.205 3.905-11.49 9.419-13.559 11.03-4.136 22.981-6.434 39.296-6.434 19.071 0 32.86 5.055 41.593 13.787 9.191 9.191 13.327 22.75 13.327 39.295v56.068c0 9.423-7.583 16.775-17.005 16.775-10.111 0-16.774-7.123-16.774-14.477v-.23c-8.502 9.421-20.224 15.625-37.226 15.625-23.211.002-42.283-13.324-42.283-37.684zm80.197-8.043v-6.204c-5.974-2.757-13.787-4.596-22.289-4.596-14.938 0-24.128 5.975-24.128 17.004v.46c0 9.422 7.813 14.936 19.072 14.936 16.315.001 27.345-8.96 27.345-21.6z" />
    </g>
  </svg>
);

const LinkIcon: React.FunctionComponent<IIconProps> = props => (
  <svg viewBox="0 0 482.8 482.8" width="1em" height="1em" {...props}>
    <path
      fill="#28D094"
      d="M255.2 209.3c-5.3 5.3-5.3 13.8 0 19.1 21.9 21.9 21.9 57.5 0 79.4l-115 115c-21.9 21.9-57.5 21.9-79.4 0l-17.3-17.3c-21.9-21.9-21.9-57.5 0-79.4l115-115c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-115 115C8.7 322.7 0 343.6 0 365.8c0 22.2 8.6 43.1 24.4 58.8l17.3 17.3c16.2 16.2 37.5 24.3 58.8 24.3s42.6-8.1 58.8-24.3l115-115c32.4-32.4 32.4-85.2 0-117.6-5.2-5.3-13.8-5.3-19.1 0z"
    />
    <path
      fill="#28D094"
      d="M458.5 58.2l-17.3-17.3c-32.4-32.4-85.2-32.4-117.6 0l-115 115c-32.4 32.4-32.4 85.2 0 117.6 5.3 5.3 13.8 5.3 19.1 0s5.3-13.8 0-19.1c-21.9-21.9-21.9-57.5 0-79.4l115-115c21.9-21.9 57.5-21.9 79.4 0l17.3 17.3c21.9 21.9 21.9 57.5 0 79.4l-115 115c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l115-115c15.7-15.7 24.4-36.6 24.4-58.8 0-22.2-8.6-43.1-24.3-58.8z"
    />
  </svg>
);

const Resource: React.FunctionComponent<IIconProps> = ({ children, ...props }) => {
  return (
    <div
      css={{
        padding: 15,
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.01)",
        },
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default Resources;
