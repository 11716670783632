import { useContext } from "react";
import useMediaWithInitialState from "~/lib/hooks/useMediaWithInitialState";
import MobileDetectContext from "~/components/MobileDetectContext";

export default function useIsTablet() {
  const mobileDetectContext = useContext(MobileDetectContext);
  const isMobile = process.browser
    ? useMediaWithInitialState("(min-width: 720px; max-width: 960px)")
    : mobileDetectContext.isTablet;
  return isMobile;
}
