import React, { ReactElement } from "react";
import { Hover } from "react-powerplug";

export default function StoryListItemHoverContainer(props: any): ReactElement {
  return (
    <Hover>
      {({ hovered, bind }) => (
        <div
          css={{
            backgroundColor: hovered ? "rgba(250, 250, 250, 0.5)" : "inherit",
          }}
          {...bind}
          {...props}
        />
      )}
    </Hover>
  );
}
