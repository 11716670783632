import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, CountryFlag, TCountryCode, Tooltip } from "@govlaunch/web";
import { DocumentNode } from "graphql";
import Link from "next/link";
import Router from "next/router";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Hover } from "react-powerplug";
import ProgressiveImage from "react-progressive-image";
import useIsDesktop from "~/lib/hooks/useIsDesktop";
import useIsMobile from "~/lib/hooks/useIsMobile";
import { useIntlLocale } from "~/lib/withIntl";
import TranslationButton from "~/src/components/translation/TranslationButton";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";
import { ICity, IGovernment, IStory, ITag } from "~/types/types";
import Auth from "~/components/auth/Auth";
import BookmarkButtonIcon from "~/components/buttons/BookmarkButtonIcon";
import LockIcon from "~/components/icons/LockIcon";
import { Margin } from "~/components/spacings";
import TagsPreview from "~/components/tag/TagsPreview";
import Dotdotdot from "~/components/text/Dotdotdot";
import StoryDefaultCover from "~/components/story/StoryDefaultCover";
import ToggleBookmark from "~/components/story/ToggleBookmark";

export type TStoryListItemStory = Pick<
  IStory,
  | "_id"
  | "slug"
  | "verticalCover"
  | "modifiedAt"
  | "title"
  | "introduction"
  | "viewerDidBookmark"
  | "viewerCanRead"
  | "createdAt"
> & {
  government?:
    | (Pick<IGovernment, "_id" | "slug" | "name" | "logo" | "state" | "isInnovator"> & {
        city: Pick<ICity, "countryName" | "country">;
      })
    | null;
  status?: IStory["status"];
  tags: Pick<ITag, "_id" | "name">[];
};

interface IStoryListItemProps {
  story: TStoryListItemStory;
  [key: string]: any;
}

interface IStoryListItemWithFragments {
  (props: IStoryListItemProps): ReactElement | null;
  fragments?: {
    fields: DocumentNode;
  };
}

const StoryListItem: IStoryListItemWithFragments = ({ story, ...props }: IStoryListItemProps): ReactElement | null => {
  const { viewerCanRead } = story;

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { locale } = useIntlLocale();

  return (
    <Hover>
      {({ hovered, bind }) => (
        <div
          css={{
            position: "relative",
          }}
        >
          <div
            {...bind}
            {...props}
            css={{
              display: "flex",
            }}
          >
            <Link href="/stories/[storySlug]" as={`/stories/${story.slug}`} passHref={true}>
              <a>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    opacity: viewerCanRead ? 1 : 0.5,
                  }}
                >
                  <ProgressiveImage src={story.verticalCover || ""} placeholder="">
                    {(src: string, loading: boolean) => {
                      if (loading) {
                        return <StoryDefaultCover height={120} width={80} />;
                      }

                      if (!src) {
                        return <StoryDefaultCover height={120} width={80} />;
                      }

                      return (
                        <img
                          src={src}
                          height={120}
                          width={80}
                          css={{
                            pointerEvents: "none",
                            borderRadius: 5,
                            objectFit: "cover",
                            minWidth: 80,
                            minHeight: 120,
                          }}
                        />
                      );
                    }}
                  </ProgressiveImage>
                </div>
              </a>
            </Link>

            <div
              css={{
                marginLeft: 15,
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <div
                css={{
                  opacity: viewerCanRead ? 1 : 0.5,
                }}
              >
                <Link href="/stories/[storySlug]" as={`/stories/${story.slug}`} passHref={true}>
                  <a>
                    {!isMobile && story.status && (
                      <Margin mb={5}>
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {story.status === "PUBLISHED" && (
                              <span
                                css={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                  color: palette.greenSuccess,
                                }}
                              >
                                <FormattedMessage defaultMessage="Published" id="w7tf2z" /> •{" "}
                              </span>
                            )}

                            {story.status === "UNPUBLISHED" && (
                              <span
                                css={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                  color: palette.red,
                                }}
                              >
                                <FormattedMessage defaultMessage="Unpublished" id="wbU4tD" /> •{" "}
                              </span>
                            )}

                            <time
                              dateTime={story.modifiedAt || story.createdAt}
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: palette.sealBlue,
                              }}
                            >
                              <IntlRelativeTime value={story.modifiedAt || story.createdAt} />
                            </time>
                          </div>
                          {locale !== "en-US" && (
                            <TranslationButton
                              paddingY="4px"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                Router.push(`/stories/${story.slug}?lang=${locale}`);
                              }}
                            />
                          )}
                        </div>
                      </Margin>
                    )}

                    <h2
                      css={{
                        fontSize: 18,
                        margin: 0,
                        color: palette.text,
                        textDecoration: "none",
                        display: "inline-flex",
                        borderBottom: "solid 1px transparent",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      <Dotdotdot clamp={2}>{story.title}</Dotdotdot>
                    </h2>

                    <Margin mt={5} mb={10}>
                      <div
                        css={{
                          fontSize: 14,
                          lineHeight: 1.1,
                          color: palette.darkGray,
                          wordBreak: "break-word",
                        }}
                      >
                        <Dotdotdot clamp={2}>{story.introduction}</Dotdotdot>
                      </div>
                    </Margin>
                  </a>
                </Link>
              </div>

              <div
                css={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  marginTop: "auto",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {story.tags && story.tags.length > 0 && <TagsPreview tags={story.tags} />}

                  {story.government && (
                    <Margin mt={8}>
                      <div
                        css={{
                          opacity: viewerCanRead ? 1 : 0.5,
                        }}
                      >
                        <Link
                          href="/governments/[governmentSlug]/[[...content]]"
                          as={`/governments/${story.government.slug}`}
                          passHref={true}
                        >
                          <a
                            css={{
                              color: palette.text,
                              textDecoration: "none",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "flex",
                              alignItems: "center",
                              paddingRight: 24,
                            }}
                          >
                            <ProfilePicture
                              size={28}
                              image={story.government.logo || ""}
                              name={story.government.name}
                              innovator={story.government.isInnovator}
                            />

                            <span
                              css={{
                                color: palette.text,
                                marginLeft: 5,
                                fontSize: 14,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Dotdotdot clamp={1}>{story.government.name}</Dotdotdot>

                              <Tooltip
                                maxWidth="220px"
                                textAlign="center"
                                label={story.government.city.countryName}
                                bg="blue.600"
                                py={2}
                              >
                                <Box display="inline-flex" ml={2}>
                                  <CountryFlag
                                    countryCode={story.government.city.country as TCountryCode}
                                    width={25}
                                    height={14}
                                  />
                                </Box>
                              </Tooltip>
                            </span>
                          </a>
                        </Link>
                      </div>
                    </Margin>
                  )}
                </div>

                <Auth>
                  {({ user }) =>
                    user && viewerCanRead && isDesktop ? (
                      <div
                        css={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                        }}
                      >
                        {hovered && (
                          <ToggleBookmark
                            targetId={story._id}
                            targetType="STORY"
                            viewerDidBookmark={story.viewerDidBookmark || false}
                          >
                            {(toggleBookmark) => (
                              <BookmarkButtonIcon
                                onClick={toggleBookmark}
                                bookmarked={story.viewerDidBookmark || false}
                                bookmarkedColor={palette.primary}
                                bookmarkedStrokeColor={palette.primary}
                              />
                            )}
                          </ToggleBookmark>
                        )}
                      </div>
                    ) : null
                  }
                </Auth>

                {!viewerCanRead && !isMobile && (
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LockIcon />

                    <span
                      css={{
                        fontSize: 14,
                        fontWeight: 600,
                        color: palette.innovatorBlue,
                        marginLeft: 10,
                      }}
                    >
                      <FormattedMessage defaultMessage="You must Sign In to read this Story" id="uVvAvk" />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!viewerCanRead && isMobile && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <LockIcon />

              <span
                css={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: palette.innovatorBlue,
                  marginLeft: 10,
                }}
              >
                <FormattedMessage defaultMessage="You must Sign In to read this Story" id="uVvAvk" />
              </span>
            </div>
          )}
        </div>
      )}
    </Hover>
  );
};

export default StoryListItem;
