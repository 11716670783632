import React from "react";

export default function GearIcon({ ...props }: React.SVGProps<any>) {
  return (
    <svg x={0} y={0} xmlSpace="preserve" width={512} height={512} viewBox="0 0 512 512" {...props}>
      <path
        d="M500.633 211.454l-58.729-14.443a193.856 193.856 0 0 0-13.55-32.256c8.818-14.678 27.349-45.571 27.349-45.571a15.001 15.001 0 0 0-2.256-18.325l-42.422-42.422a14.958 14.958 0 0 0-18.325-2.256L347.055 83.53a193.24 193.24 0 0 0-32.065-13.433l-14.443-58.729A14.996 14.996 0 0 0 286 0h-60c-6.885 0-12.891 4.688-14.546 11.367 0 0-10.005 40.99-14.429 58.715a192.571 192.571 0 0 0-34.043 14.502l-47.329-28.403c-5.918-3.516-13.447-2.607-18.325 2.256l-42.422 42.422a15.001 15.001 0 0 0-2.256 18.325l29.268 48.882a193.296 193.296 0 0 0-11.821 28.901l-58.729 14.487A14.994 14.994 0 0 0 0 226v60c0 6.885 4.688 12.891 11.367 14.546l58.744 14.443c3.56 11.294 8.188 22.266 13.799 32.798l-26.191 43.652a15.001 15.001 0 0 0 2.256 18.325l42.422 42.422c4.849 4.849 12.407 5.771 18.325 2.256 0 0 29.37-17.607 43.755-26.221a193.254 193.254 0 0 0 32.549 13.696l14.429 58.715C213.109 507.313 219.115 512 226 512h60a14.995 14.995 0 0 0 14.546-11.367l14.429-58.715a190.535 190.535 0 0 0 33.281-14.136c14.78 8.862 44.443 26.66 44.443 26.66 5.903 3.53 13.462 2.622 18.325-2.256l42.422-42.422a15.001 15.001 0 0 0 2.256-18.325l-26.968-44.927a192.1 192.1 0 0 0 13.169-31.523l58.729-14.443A14.993 14.993 0 0 0 512 286v-60a14.994 14.994 0 0 0-11.367-14.546zM256 361c-57.891 0-105-47.109-105-105s47.109-105 105-105 105 47.109 105 105-47.109 105-105 105z"
        data-original="#000000"
        className="prefix__active-path"
        data-old_color="#818295"
        fill="#828396"
      />
    </svg>
  );
}
